import React, { useState } from 'react';
import styled from "styled-components";

const Navbar = () => {
    const [isNavExpanded, setIsNavExpanded] = useState(false);
     return (
    <Wrapper>
      <nav className="navigation">
      <a href="/" className="brand-name">DVN GROUP</a>
      <button
        className="hamburger"
        onClick={() => {
          setIsNavExpanded(!isNavExpanded);
        }}
      >
        {/* icon from Heroicons.com */}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5"
          viewBox="0 0 20 20"
          fill="white"
        >
          <path
            fillRule="evenodd"
            d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
            clipRule="evenodd"
          />
        </svg>
      </button>
      <div
        className={
          isNavExpanded ? "navigation-menu expanded" : "navigation-menu"
        }
      >
        <ul>
          <li>
            <a href="/home">Home</a>
          </li>
          <li>
            <a href="/about">About</a>
          </li>
          <li>
            <a href="/products">Products</a>
          </li>
          <li>
            <a href="/contact">Contact</a>
          </li>
        </ul>
      </div>
    </nav>
    </Wrapper>
  );
};

const Wrapper = styled.nav`
.navigation {
    position: relative;
    display: flex;
    align-items: center;
    height: 60px;
    width: 100%;
    padding: 0.5rem 0rem;
    background-color: #ef6367;
    color: black;
    box-shadow: 0 2px 2px 2px rgb(9 9 9 / 17%);
  }
  
  .brand-name {
    text-decoration: none;
    color: #fff;
    font-size: 1.3rem;
    margin-left: 1rem;
  }
  
  .navigation-menu {
    margin-left: auto;
  }
  
  .navigation-menu ul {
    display: flex;
    padding: 0;
    margin: 0;
  }
  
  .navigation-menu li {
    list-style-type: none;
    margin: 0 1rem;
  }
  
  .navigation-menu li a {
    text-decoration: none;
    display: block;
    width: 100%;
    color: #fff;
  }
  
  .hamburger {
    border: 0;
    height: 40px;
    width: 40px;
    padding: 0.5rem;
    border-radius: 50%;
    background-color: #ef6367;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    position: absolute;
    top: 50%;
    right: 25px;
    transform: translateY(-50%);
    display: none;
  }
  
  .hamburger:hover {
    background-color: #ef6367;
  }
  
  @media screen and (max-width: 768px) {
    .hamburger {
      display: block;
    }
    .navigation-menu ul {
      flex-direction: column;
      position: absolute;
      top: 60px;
      left: 0;
      width: 100%;
      height: calc(100vh - 60px);
      background-color: white;
      display: none;
      z-index: 11;
    }
  
    .navigation-menu li {
      text-align: center;
      margin: 0;
    }
  
    .navigation-menu li a {
      color: black;
      width: 100%;
      padding: 1.5rem 0;
    }
  
    .navigation-menu li:hover {
      background-color: #eee;
    }
  
    .navigation-menu.expanded ul {
      display: block;
    }
  }
`; 
export default Navbar;
