//import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './Components/menu';
import About from './About';
import Contact from './Contact';
import Home from './Home';
import Products from './Products';
import Searchbar from './Components/search';
import Slider from './Components/slider';
import CategoriesSlider from './Components/categoriesSlider'; 
import Product from './Components/product';
import Footer from './Components/footer';

import '@coreui/coreui/dist/css/coreui.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {

  const images = [
    './img/slider/hills.jpg',
    './img/slider/group.jpg',
    './img/slider/cloudsmountains.jpg',
    // Add more image URLs as needed
  ];

  // const products = [
  //   { title: 'Product 1', image: 'image1.jpg', description: 'Description 1' },
  //   { title: 'Product 2', image: 'image2.jpg', description: 'Description 2' },
  //   { title: 'Product 3', image: 'image3.jpg', description: 'Description 3' },
  //   // Add more product items as needed
  // ];

  const categories = [
    { id: 1, name: 'MEN', image: './img/categories/category1.jpg' },
    { id: 2, name: 'WOMEN', image: './img/categories/category3.jpg' },
    { id: 3, name: 'KIDS', image: './img/categories/category2.jpg' },
    
    // Add more categories as needed
  ];

  const product = [
    { id: 1, name: 'Product 1', image: './img/categories/category1.jpg' },
    { id: 2, name: 'Product 2', image: './img/categories/category3.jpg' },
    { id: 3, name: 'Product 3', image: './img/categories/category2.jpg' },
    
    // Add more categories as needed
  ];

  return (
    <div className="app-container">
          <Router>
            <Navbar/>
            <Routes>
              <Route path='/' exact component={Home} />
              <Route path='/about' component={About} />
              <Route path='/products' component={Products} />
              <Route path='/contact' component={Contact} />
            </Routes>
          </Router>
          <Searchbar/>
          <Slider images={images}/>
          <CategoriesSlider categories={categories} />
          <Product product={product} />
            
          {/* <BottomTabNavigator /> */}
          <Footer/>
    </div>
  );
}
export default App;
