import React from 'react';
//import { CFooter, CLink } from '@coreui/react';
import styled from "styled-components";


const footer = () => {
  return (
    <Wrapper>
        {/* <CFooter>
        <div>
            <CLink href="https://coreui.io">CoreUI</CLink>
            <span>&copy; 2023 creativeLabs.</span>
        </div>
        <div>
            <span>Powered by</span>
            <CLink href="https://coreui.io">CoreUI</CLink>
        </div>
        </CFooter> */}
        <footer class="footer">
            <div>
                <span>&copy; 2023 All Rights Reserved with Copyright & TradeMarks.</span>
            </div>
            <div>
                <span>Develop by </span>
                <a href="https://dvnitsolutions.com/" target='_blank'> DVN IT SOLUTIONS</a>
            </div>
        </footer>
    </Wrapper>
  )
}
export default footer;

const Wrapper = styled.div`
.footer{
    background: #ef6367;
      color: #fff;
    }
    .footer a{
        color:#000;
        text-decoration:none;
    }
`;