import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styled from "styled-components";
import { faRupeeSign } from '@fortawesome/fontawesome-free-solid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const Product = ({ product }) => {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
          },
        },
      ],
    };
  
    return (
        <Wrapper>
          <div className='container'> 
            <div className='heading'>
              <h2>Our Product</h2>
                <a
                  // onClick={caterories}
                  href="/product"
                  rel="product"
                >View All</a>
            </div>
            <Slider {...settings}>
              {product.map((product) => (
                <div key={product.id} className="category">
                  <img src={product.image} alt={product.name} className='mb-2' />
                  <div className='product-name'>
                    <h5 className='prise'><FontAwesomeIcon icon={faRupeeSign} /> 30 
                    <del><sup><FontAwesomeIcon className='small-letter' icon={faRupeeSign} /> 35.99</sup></del></h5>
                    <h5 className='pro-name'>{product.name}</h5>
                  </div>
                </div>
              ))}
            </Slider>
                </div>
        </Wrapper>
    );
  };
  

const Wrapper = styled.section`
    padding-bottom: 3rem;
  h2{font-size:1.2rem;}
  .heading {
    justify-content: space-between;
    display: flex;
    align-items: center;
    margin: 0px 20px;
  }
  a {
    color: #ef6367;
    font-size:0.9rem;
  }
  .category {
    text-align: left;
    box-shadow: 0 2px 10px -5px #000000;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  h5{
    margin:0px;
    color: #5a5757;
    font-size: 1rem;
    font-weight: 500;
    padding: 0px 15px 5px 15px;
  }
  .fa-rupee-sign{
    font-weight:500;
    font-size:0.9rem;
  }
  del{
    color:#5a5757;
    padding-left:10px;
    font-size:0.9rem;
    }

  .prise{
    color:#ef6367;
    }
  .pro-name{
    padding-bottom:10px;
    }
  
  .category img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .small-letter{
    font-size: 0.7rem;
  }
  .slick-track{
    margin-bottom: 10px;
  }
  .slick-arrow {
    display:none;
  }
  .slick-slide div { 
    margin: 0 10px;
  }
    `; 

export default Product;


