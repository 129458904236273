import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styled from "styled-components";

const CategoriesSlider = ({ categories }) => {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
          },
        },
      ],
    };
  
    return (
        <Wrapper>
            <div className='container'> 
                    <div className='heading'>
                      <h2>Categories</h2>
                      <a
                     // onClick={caterories}
                      href="/caterories"
                      rel="noreferrer"
                    >
                     View All
                    </a>
                    </div>
                    <Slider {...settings}>
                    {categories.map((category) => (
                        <div key={category.id} className="category">
                          <img src={category.image} alt={category.name} />
                          <div className='product-name'>
                            <h5>{category.name}</h5>
                          </div>
                        </div>
                    ))}
                    </Slider>
                </div>
        </Wrapper>
    );
  };
  

const Wrapper = styled.section`
  padding-top: 2rem;
  padding-bottom: 2rem;
h2{font-size:1.2rem;}

.heading {
  justify-content: space-between;
  display: flex;
  align-items: center;
  margin: 0 20px;
}
a {
  color: #ef6367;
  font-size:0.9rem;
}
.category {
  text-align: left;
  box-shadow: 0 2px 10px -5px #000000;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}
h5{
  margin:0px;
  color: #5a5757;
  font-size: 1rem;
  font-weight: 500;
}
.fa-rupee-sign{
  font-weight:500;
  font-size:0.9rem;
}
.category img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.product-name{
  padding:15px;
}
.slick-track{
  margin-bottom: 10px;
}
.slick-arrow{
  display:none!important;
}
.slick-slide div { 
  margin: 0 10px;
}

  `; 

export default CategoriesSlider;


