import React from 'react';
import styled from "styled-components";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/fontawesome-free-solid';

const Searchbar = () => {
  return (
    <Wrapper>
      <div className='container'>
        <div className='input-wrap'>
            <FontAwesomeIcon icon={faSearch} />
            <label 
              for="product-search" 
              id="input-label"
            >
              Product Search
            </label>
            <input 
              //onChange={handleInput}
              //value={searchVal}
              type="text" 
              name="product-search" 
              id="product-search" 
              placeholder="Search Your Product"
            />
        </div>
      </div>
   </Wrapper>
  )
};

export default Searchbar;

const Wrapper = styled.div`
#input-label {
    display: none;
    color:#cdc3c3;
  }
  .fa-search{
    color:#cdc3c3;
  }
  .input-wrap {
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.7rem;
    border-radius: 0.8rem;
    box-sizing: border-box;
    border: 1px solid #cdc3c3;
    margin: 15px auto;
  }
  
  .input-wrap FontAwesomeIcon {
    font-size: 2rem;
    color: #cdc3c3;
  }

  #product-search {
    font-size: 1rem;
    color: #cdc3c3;
    width: 100%;
    height: 100%;
    padding: 0 0 0 1rem;
    border: none;
    outline: none;
  }
  
  #product-search::placeholder {
    color: #cdc3c3; 
  }
  
  .fa-times:hover {
    cursor: pointer;
  }

`;