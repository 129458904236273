import React, { useState } from 'react';
//import './Slider.css';
import styled from "styled-components";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowLeft } from '@fortawesome/fontawesome-free-solid';

const Slider = ({ images }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const handlePrevSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide === 0 ? images.length - 1 : prevSlide - 1));
  };

  const handleNextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide === images.length - 1 ? 0 : prevSlide + 1));
  };

  return (
    <Wrapper>
        <div className="slider">
          <img className="slider-image" src={images[currentSlide]} alt="Slider" />
          <div className="slider-buttons">
            <button className="prev-button" onClick={handlePrevSlide}>
                <FontAwesomeIcon icon={faArrowLeft} />
            </button>
            <button className="next-button" onClick={handleNextSlide}>
              <FontAwesomeIcon icon={faArrowRight} />
            </button>
          </div>
        </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
.slider {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.slider-image {
  width: 100%;
  height: 100%;
  transition: transform 0.3s ease-in-out;

}

.slider-buttons {
  position: absolute;
  bottom: 10px;
  right: 0%;
  transform: translateX(-50%);

}

.prev-button,
.next-button {
  background-color: transparent;
  border: none;
  padding: 0px 3px;
  margin: 0 5px;
  cursor: pointer;
  color: #fff;
}
@media screen and (max-width: 768px) {
  .slider-image {
    border-radius: 10px;
  }
  .slider{
    width: 90%;
    margin: 0 auto;
  }
}
`;

export default Slider;
